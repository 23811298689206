import { FunctionComponent } from 'react'
import { Link } from 'gatsby'

const IndexPage: FunctionComponent = () => {
  return (
    <main>
      <title>Home Page</title>
      <h1>This is the main page.</h1>
      <Link to="/welcome">Welcome Page</Link>
    </main>
  )
}

export default IndexPage
